<template>
	<div
		class="modal fade"
		id="assignRouteModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="assignRouteLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="driver"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="assignRouteLabel">
						{{ action | titleCase }} Route to Driver
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="assignOrUnasignRoute">
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Action</label>
								<select v-model="action" class="form-select form-control mb-3">
									<option value="assign">Assign Route</option>
									<option value="unassign">Unassign Route</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Route</label>
								<v-select
									v-model="form.route"
									class="form-group"
									:options="routes"
									label="pickup"
									required
									:placeholder="`Select a route to ${action == 'assign' ? 'assign' : 'unassign'}`"
									:filter="fuseSearch"
									>
									<template v-slot:option="route">
										<span v-if="route.route_code">{{ `${route.route_code} - ` }}</span>
										{{ `${route.pickup} to ${route.destination}` }}
									</template>
									<template v-slot:selected-option="route">
										<span v-if="route.route_code">{{ `${route.route_code} - ` }}</span>
										{{ `${route.pickup} to ${route.destination}` }}
									</template>
								</v-select>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="assignOrUnasignRoute"
						:disabled="processing || !formValid"
						>
						<template v-if="processing">Processing...</template>
						<template v-else>
							{{ action | titleCase }} Route
						</template>
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#assignRouteModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'
import Fuse from 'fuse.js'
import routeResource from '@/api/route'

export default {
  props: {
    show: Boolean,
    driver: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        route: null
		},
      loading: false,
      errorLoading: false,
      processing: false,
      errorMessage: '',
      driverRoutes: [],
      allRoutes: [],
      action: 'assign'
    }
  },
  created () {
    this.fetchRoutes()
  },
  computed: {
    formValid () {
      return this.form.route
    },
    routes () {
      if (!['assign', 'unassign'].includes(this.action)) {
        return []
      }

      return this.action === 'assign' ? this.allRoutes : this.driverRoutes
    }
  },
  methods: {
    fuseSearch (options, search) {
      const fuse = new Fuse(options, {
        keys: ['route_code', 'pickup', 'destination'],
        shouldSort: true
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
    fetchRoutes () {
      this.loading = true

      Promise.all([this.fetchAllRoutes(), this.fetchDriverRoutes()])
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchAllRoutes () {
      return routeResource.listRoute().then((data) => {
        this.allRoutes = data
      })
    },
	  fetchDriverRoutes()
	  {
      return this.axios
        .get(`/v1/drivers/${this.$route.params.driverId}/routes`)
        .then((res) => {
			this.driverRoutes = res.data.routes
        })
    },
    assignOrUnasignRoute () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      const method = this.action === 'assign' ? 'POST' : 'DELETE'
      this.axios({
        method,
        url: `/v1/drivers/${this.$route.params.driverId}/routes`,
        data: {
          route_id: this.form.route.id
        }
      })
        .then(() => {
          this.close()

          this.$emit('processed', this.form.route.id)

          this.form.route = null

          this.$swal({
            icon: 'success',
            title: `Route ${this.action}ed`,
            text: `Route has been ${this.action}ed successfully`,
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
