<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-sm col-xl-7">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Driver Information</h4>
							<div class="dropdown">
								<button
									class="btn btn-danger dropdown-toggle"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-expanded="false"
									>
									Actions
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<router-link
										v-if="userHasPermission('drivers004')"
										:to="{
											name: 'ManageDriver',
											params: { driverId: driver?.id },
										}"
										class="dropdown-item"
										>Edit Driver</router-link
										>
									<a
										v-if="userHasPermission('drivers009')"
										class="dropdown-item"
										href="#"
										@click.prevent="showAssignBusToDriver = true"
										>Assign Bus</a
										>
									<a
										class="dropdown-item"
										href="#"
										@click.prevent="showEditDriverBus = true"
										>Edit Bus</a
										>
									<a
										v-if="userHasPermission('drivers008')"
										class="dropdown-item"
										href="#"
										@click.prevent="showAssignRouteToDriver = true"
										>Assign/Unassign Route</a
										>
									<a
										class="dropdown-item"
										href="#"
										@click.prevent="showStartOrStopTrip = true"
										>Start or Stop Trip</a
										>
									<a
										v-if="userHasPermission('drivers007')"
										class="dropdown-item"
										href="#"
										@click.prevent="showChangePassword = true"
										>Change Password</a
										>
									<a
									v-if="userHasPermission('drivers004')"
										class="dropdown-item"
										href="#"
										@click.prevent="showChangeProfilePicture = true"
										>Add Profile Picture</a
										>
									<a
										v-if="userHasPermission('drivers006')"
										class="dropdown-item"
										:class="isDriverActive ? 'text-danger' : 'text-success'"
										href="#"
										@click.prevent="suspendOrUnsuspendDriver"
										>{{ isDriverActive ? 'Suspend' : 'Unsuspend' }} Driver</a
										>
									<a
										v-if="userHasPermission('drivers005')"
										class="dropdown-item text-danger"
										href="#"
										@click.prevent="deleteDriver"
										>Delete Driver</a
										>
									<a
										class="dropdown-item"
										:class="!isDriverBlocked ? 'text-danger' : 'text-success'"
										href="#"
										v-if="isDriverBlocked"
										@click.prevent="unblockDriver"
										>Unblock Driver</a
										>
								</div>
							</div>
						</div>
						<div class="card-body p-2">
							<div class="list-group list-group-flush mb-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Name</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												`${driver?.fname} ${driver?.lname}`
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Avatar</span>
										</div>
										<div class="col-auto">
											<user-avatar :user="driver" size="sm"></user-avatar>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Phone Number</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{ driver?.phone ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Email Address</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{ driver?.email ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>RESIDENTIAL ADDRESS</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												driver?.residential_address ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>DATE OF BIRTH</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{ driver?.dob ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Date Joined</span>
										</div>
										<div class="col-auto">
											{{ driver?.created_at | date('dd/MM/yyyy') }}
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>VEHICLE ASSIGNED</span
												>
										</div>
										<div class="col-auto">
											<template v-if="driver.vehicle">
												<router-link
													style="color: #4848ed"
													:to="{
														name: 'ShowVehicle',
														params: { vehicleId: driver?.vehicle?.id },
													}"
													>
													{{ driver?.vehicle?.seats }} Seater -
													{{ driver?.vehicle?.brand }}
													{{ driver?.vehicle.name }}
													{{ driver?.vehicle?.registration_number }}
												</router-link>
											</template>
											<template v-else>No vehicle assigned</template>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Recent Device</span
												>
										</div>
										<div class="col-auto">
											<span v-if="driver?.device">{{
												driver?.device?.device_brand
											}}</span>
											<span v-else>N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>VEHICLE PARTNER</span
												>
										</div>
										<div class="col-auto">
											<router-link
												:to="{
													name: 'PartnerDetails',
													params: {
														partnerId: driver?.partner?.id,
														accountSid: driver?.partner.account_sid,
													},
												}"
												v-if="driver?.partner"
												style="text-decoration: underline; color: #4848ed"
												>{{
													driver?.partner?.fname ??
														driver?.partner?.company_name
												}}</router-link
												>
											<span v-else>N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>ROUTE ASSIGNED</span
												>
										</div>
										<div class="col-auto">
											<div v-if="driver?.routes?.length">
												<span
													v-for="(route, index) in driver?.routes"
													:key="index"
													>{{ route.route_code }}</span
													>
											</div>
											<span v-else>N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">
												LICENSE NUMBER</span
												>
										</div>
										<div class="col-auto">
											<span>{{ driver?.vehicle?.registration_number }}</span>
										</div>
									</div>
								</div>

								<!-- <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase"
                        >LICENSE VERIFICATION STATUS</span
                      >
                    </div>
                    <div class="col-auto">
                      <span
                        :class="
                          driver.vehicle.license_verification_status !==
                          'not_submitted'
                            ? 'text-success'
                            : 'text-danger'
                        "
                        >{{ driver.vehicle.license_verification_status }}</span
                      >
                    </div>
                  </div>
                </div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm col-xl-5">
					<!-- <partner-earnings :total-earnings="partnerWallet"> </partner-earnings> -->

					<div class="card">
						<div class="card-body">
							<div
								v-if="driverDocuments?.length"
								class="list-group list-group-flush"
								>
								<div
									v-for="(document, index) in driverDocuments"
									:key="index"
									class="list-group-item pt-2 pb-3 px-0"
									>
									<div
										class="d-flex justify-content-between align-items-center"
										>
										<div
											class="d-flex justify-content-between align-items-center"
											>
											<img src="@/assets/img/pdf_download.svg" />
											<div class="col d-flex flex-column">
												<span
													class=""
													v-if="document?.document_type === 'drivers_license'"
													>Drivers license</span
													>
												<span class="" v-else>{{
													document.document_type
												}}</span>
												<small
													:class="[
														document.status === 'failed'
															? 'text-danger'
															: 'text-success',
													]"
													>{{ document.status }}</small
													>
											</div>
										</div>
										<div class="">
											<a :href="document.files[0]" target='_blank' style="color: #4848ed; text-decoration: underline"
												>View document</a
												>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="d-flex align-items-center">
								<img src="@/assets/img/pdf_download.svg" class="mr-2" />
								<small class="text-muted">No documents available</small>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-xl-12">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Rides by Driver</h4>

								<small class="text-muted me-3"
									>Rides by driver over the last 30 days</small
									>
							</div>
						</div>
						<div class="card-body">
							<bar-chart
								v-if="ridesByDriverLast30Days"
								class="chart barchart"
								:chart-data="ridesByDriverLast30Days"
								:options="barChartOptions"
								></bar-chart>
							<div
								v-if="loadingDriverTripsChartData"
								class="d-flex justify-content-center align-items-center"
								>
								<div class="spinner-border" role="status"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="driver">
			<add-route-to-entity-modal
				:show="showAddDriverRoute"
				:entity="driver"
				@close="showAddDriverRoute = false"
				@route-added="processRouteAdded"
				></add-route-to-entity-modal>
			<change-user-password-modal
				:show="showChangePassword"
				:user="driver"
				entity-type="drivers"
				@close="showChangePassword = false"
				></change-user-password-modal>
			<change-user-profile-picture-modal
				:show="showChangeProfilePicture"
				:user="driver"
				entity-type="driver"
				@close="showChangeProfilePicture = false"
				@profile-updated="fetchDriver"
				></change-user-profile-picture-modal>
			<assign-bus-to-driver-modal
				:show="showAssignBusToDriver"
				:driver="driver"
				:vehicles="vehicles"
				@close="showAssignBusToDriver = false"
				@vehicle-assigned="processVehicleAssigned"
				></assign-bus-to-driver-modal>
			<assign-route-to-driver-modal
				:show="showAssignRouteToDriver"
				:driver="driver"
				@close="showAssignRouteToDriver = false"
				></assign-route-to-driver-modal>
			<edit-driver-bus-modal
				:show="showEditDriverBus"
				:driver="driver"
				@close="showEditDriverBus = false"
				@vehicle-updated="processVehicleUpdated"
				></edit-driver-bus-modal>
			<start-or-stop-driver-trip-modal
				:show="showStartOrStopTrip"
				:driver="driver"
				@close="showStartOrStopTrip = false"
				></start-or-stop-driver-trip-modal>
		</template>
	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import { getYearlyBarChartData } from '@/utils/chart'
import Swal from 'sweetalert2'
import AddRouteToEntityModal from '@/components/modals/AddRouteToEntityModal.vue'
import ChangeUserPasswordModal from '@/components/modals/ChangeUserPasswordModal.vue'
import ChangeUserProfilePictureModal from '@/components/modals/ChangeUserProfilePictureModal.vue'
import AssignBusToDriverModal from '@/components/modals/AssignBusToDriverModal.vue'
import UserAvatar from '@/components/core/UserAvatar.vue'
import AssignRouteToDriverModal from '@/components/modals/AssignRouteToDriverModal.vue'
import EditDriverBusModal from '@/components/modals/EditDriverBusModal.vue'
import StartOrStopDriverTripModal from '@/components/modals/StartOrStopDriverTripModal.vue'

export default {
  props: {
    driverId: {
      required: true,
      type: [Number, String]
    }
  },
  components: {
    BarChart,
    AddRouteToEntityModal,
    ChangeUserPasswordModal,
    ChangeUserProfilePictureModal,
    UserAvatar,
    AssignBusToDriverModal,
    AssignRouteToDriverModal,
    EditDriverBusModal,
    StartOrStopDriverTripModal
  },
  data () {
    return {
      driver: {},
      processingExecution: false,
      loading: true,
      errorLoading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      docUrl: null,
      error: null,
      companies: null,
      ridesByDriverLast30Days: null,
      loadingDriverTripsChartData: true,
      errorLoadingDriverTripsChartData: false,
      trips: [],
      vehicles: [],
      showAddDriverRoute: false,
      showChangePassword: false,
      showChangeProfilePicture: false,
      showAssignBusToDriver: false,
      showAssignRouteToDriver: false,
      showEditDriverBus: false,
      showStartOrStopTrip: false,
      showDocument: false,
      barChartOptions: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              offset: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                color: '#F6F6F7'
              },
              ticks: {
                // stepSize: 500,
              }
            }
          ]
        }
      },
      driverDocuments: []
    }
  },
  created () {
    this.init()
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    },
    isDriverActive () {
      if (!this.driver) {
        return false
      }

      return !!parseInt(this.driver?.active)
    },
    driverVehicle () {
      if (!this.driver || !this.driver?.vehicle_id) {
        return
      }

      return this.vehicles.find(
        (vehicle) => vehicle?.id === this.driver?.vehicle_id
      )
    },
    isDriverBlocked () {
      return !!this.driver?.is_blocked
    },
    driverDevice () {
      return this.driver &&
        Object.prototype.hasOwnProperty.call(this.driver, 'device')
        ? this.driver?.device?.device_brand
        : 'Not Available'
    }
    // modifiedDocUrl:{
    //   get(){
    //     return ''
    //   }
    // },
  },
  methods: {
    handleClose () {
      this.index = null
      this.showDocument = false
    },

    async init () {
      this.loading = true
      this.errorLoading = false

      this.fetchDriver()
        .then(() => {
          this.fetchDriverTripsChartData()
          this.fetchDriverTrips()
          this.fetchVehicles()
          this.fetchUploadedDriverDocument()
        })
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchDriver () {
      return this.axios.get(`/v1/drivers/${this.driverId}`).then((res) => {
        this.driver = res.data
        this.driverDocuments = res.data.documents || []
      })
    },
    fetchDriverTripsChartData () {
      this.loadingDriverTripsChartData = true
      this.errorLoadingDriverTripsChartData = false

      this.axios
        .get(`/v1/drivers/${this.driverId}/trips/graph`)
        .then((res) => {
          this.ridesByDriverLast30Days = getYearlyBarChartData(res.data)
        })
        .catch(() => {
          this.errorLoadingDriverTripsChartData = true
        })
        .finally(() => (this.loadingDriverTripsChartData = false))
    },
    fetchDriverTrips () {
      this.axios.get(`/v1/drivers/${this.driverId}/trips`).then((res) => {
        this.trips = res.data
      })
    },
    fetchVehicles () {
      return this.axios.get('/v1/vehicles').then((res) => {
        this.vehicles = res.data.data
      })
    },
    async suspendOrUnsuspendDriver () {
      const isSuspendAction = this.isDriverActive

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isDriverActive ? 'suspend' : 'unsuspend'
        } this driver?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/drivers/${this.driverId}`, {
              active: this.isDriverActive ? 0 : 1
            })
            .then((res) => {
              this.driver = res.data

              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `Driver has been ${
              isSuspendAction ? 'suspended' : 'unsuspended'
            } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    async deleteDriver () {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete this driver?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .delete(`/v1/drivers/${this.driverId}`)
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Driver has been deleted successfully',
            showCloseButton: true
          })
        }
      })
    },
    async unblockDriver () {
      const isBlockedAction = this.isDriverBlocked
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isDriverBlocked ? 'unblock' : 'block'
        } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/users/${this.driverId}/blocked`, {
              isBlocked: !this.isDriverBlocked
            })
            .then((res) => {
              this.user = res.data
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `user has been ${
              isBlockedAction ? 'unblocked' : 'blocked'
            } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    processRouteAdded () {
      this.fetchDriverTrips()
    },
    processVehicleAssigned (vehicleId) {
      this.driver.vehicle_id = vehicleId
    },
    processVehicleUpdated (vehicle) {
      this.driver.vehicle = { ...vehicle }
    }
  }
}
</script>

<style scoped>
</style>
