<template>
	<div
		class="modal fade"
		id="startStopTripModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="startStopTripLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="driver"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="startStopTripLabel">
						{{ action | titleCase }} Trip Route
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="startOrStopTrip">
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Action</label>
								<select v-model="action" class="form-select form-control mb-3">
									<option value="start">Start Trip</option>
									<option value="end">End Trip</option>
								</select>
							</div>
						</div>
						<template v-if="action === 'start'">
							<div v-if="driverRoutes.length">
								<div class="row">
									<div class="col-12">
										<label class="form-label">Select Route</label>
										<v-select
											v-model="form.route"
											class="form-group"
											:options="driverRoutes"
											label="pickup"
											required
											placeholder="Select a route to start"
											>
											<template v-slot:option="route">
												<span v-if="route.code">{{ `${route.code} - ` }}</span>
												{{ `${route.pickup} to ${route.destination}` }}
											</template>
											<template v-slot:selected-option="route">
												<span v-if="route.code">{{ `${route.code} - ` }}</span>
												{{ `${route.pickup} to ${route.destination}` }}
											</template>
										</v-select>
									</div>
								</div>
							</div>
							<div v-else>
								There are no routes for this driver
							</div>
						</template>
						<template v-if="action === 'end'">
							<div v-if="activeTrips.length">
								<div class="row">
									<div class="col-12">
										<label class="form-label">Select Trip</label>
										<v-select
											v-model="form.trip"
											class="form-group"
											:options="activeTrips"
											label="pickup"
											required
											placeholder="Select a route to start"
											>
											<template v-slot:option="trip">
												<span v-if="trip.route.code">{{ `${trip.route.code} - ` }}</span>
												{{ `${trip.route.pickup} to ${trip.route.destination}` }}
											</template>
											<template v-slot:selected-option="trip">
												<span v-if="trip.route.code">{{ `${trip.route.code} - ` }}</span>
												{{ `${trip.route.pickup} to ${trip.route.destination}` }}
											</template>
										</v-select>
									</div>
								</div>
							</div>
							<div v-else>
								There are no routes for this driver
							</div>
						</template>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="startOrStopTrip"
						:disabled="processing || !formValid"
						>
						<template v-if="processing">Processing...</template>
						<template v-else> {{ action | titleCase }} Trip </template>
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#startStopTripModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    driver: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        route: null,
        trip: null
      },
      loading: false,
      errorLoading: false,
      processing: false,
      errorMessage: '',
      driverRoutes: [],
      activeTrips: [],
      action: 'start'
    }
  },
  created () {
    this.fetchTrips()
	},
    watch: {
    show: {
      immediate: true,
      handler: function (value) {
			this.errorMessage = ''
        if (value) {
          this.$nextTick(() => {
            this.$refs.modalToggle.click()
          })
        }
      }
    }
  },
  computed: {
    formValid () {
      return this.form.route || this.form.trip
    }
  },
	methods: {
	   close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton

      target.click()

      this.$emit('close')
    },
    fetchTrips () {
	this.errorMessage = ''
      this.loading = true

      Promise.all([this.fetchDriverActiveTrips(), this.fetchDriverRoutes()])
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchDriverActiveTrips () {
      return this.axios
        .get(`/v1/drivers/${this.$route.params.driverId}/active-trips`)
        .then((res) => {
          this.activeTrips = res.data
        })
    },
	  fetchDriverRoutes()
	  {
      return this.axios
        .get(`/v1/drivers/${this.$route.params.driverId}/routes`)
        .then((res) => {
          this.driverRoutes = res.data.routes
        })
    },
    startOrStopTrip () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      let requestObj = {}

      if (this.action === 'start') {
        requestObj = {
          method: 'POST',
          url: '/v1/trips',
          data: {
            route_id: this.form.route.id,
            pickup_coordinate: this.form.route.pickup_coordinate,
            driver_id: this.$route.params.driverId
          }
        }
      } else {
        requestObj = {
          method: 'PATCH',
          url: `/v1/trips/${this.form.trip.id}`,
          data: {
            destination_coordinate:
              this.form.trip.route.destination_coordinate ||
              '6.5135466666661115, 3.3668908333333337'
          }
        }
      }

      this.axios({
        ...requestObj
      })
        .then(() => {
          this.close()

          this.$emit('processed', this.form.route.id)

          this.form.route = null

          this.$swal({
            icon: 'success',
            title: `Trip ${this.action}ed`,
            text: `Trip has been ${this.action}ed successfully`,
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: e.message || 'An error occured while processing your request',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
