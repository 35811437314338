<template>
	<div
		class="modal fade"
		id="driverBusModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="driverBusLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="driver && form.vehicle"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="driverBusLabel">
						Edit Vehicle Information
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="updateVehicle">
						<div class="form-group row">
							<div class="col-12">
								<label class="form-label">Vehicle Brand</label>
								<input
									v-model.trim="form.vehicle.brand"
									required
									type="text"
									class="form-control"
									/>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-12">
								<label class="form-label">Vehicle Name</label>
								<input
									v-model.trim="form.vehicle.name"
									required
									type="text"
									class="form-control"
									/>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-12">
								<label class="form-label">Vehicle Plate Number</label>
								<input
									v-model.trim="form.vehicle.registration_number"
									required
									type="text"
									class="form-control"
									/>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-12 col-md-6">
								<label class="form-label">Vehicle Capacity</label>
								<input
									v-model.trim="form.vehicle.seats"
									required
									type="number"
									class="form-control"
									/>
							</div>
							<div class="col-12 col-md-6">
								<label class="form-label">Vehicle Type</label>
								<select
									v-model="form.vehicle.type"
									class="form-control form-select"
									>
									<option :value="null">Select</option>
									<option v-for="(option, index) in vehicleTypes"
										:value="option.name"
										:key="index">{{option.name}}</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-12 col-md-6">
								<label class="form-label">Vehicle Code</label>
								<input
									v-model.trim="form.vehicle.busCode"
									required
									type="text"
									class="form-control"
									min="1"
									max="6"
									/>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-12">
								<label class="form-label">Vehicle Amenities</label>
								<input
									v-model.trim="form.vehicle.amenities"
									type="text"
									class="form-control"
									min="1"
									max="6"
									/>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="updateVehicle"
						:disabled="processing || !formValid"
						>
						<template v-if="processing">Processing...</template>
						<template v-else>Update Vehicle</template>
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#driverBusModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    driver: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        vehicle: null
      },
      loading: false,
      errorLoading: false,
      processing: false,
      errorMessage: '',
      driverRoutes: [],
      allRoutes: [],
      action: 'assign',
      vehicleTypes: []
    }
  },
  created () {
    this.form.vehicle = { ...this.driver.vehicle }
    this.fetchVehicleTypes()
  },
  computed: {
    formValid () {
      return this.form.vehicle
    }
  },
  methods: {
    fetchVehicleTypes () {
      this.axios.get('/v1/vehicle-types').then((res) => {
        this.vehicleTypes = res.data.data
      })
    },
    updateVehicle () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/vehicles/${this.driver.vehicle.id}`, {
          brand: this.form.vehicle.brand,
          name: this.form.vehicle.name,
          type: this.form.vehicle.type,
          seats: this.form.vehicle.seats,
          registration_number: this.form.vehicle.registration_number,
          amenities: this.form.vehicle.amenities,
          code: this.form.vehicle.busCode
        })
        .then(() => {
          this.close()

          this.$emit('vehicle-updated', this.form.vehicle)

          this.form.vehicle = null

          this.$swal({
            icon: 'success',
            title: 'Vehicle Updated',
            text: 'Vehicle information has been updated successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
